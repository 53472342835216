<template>
  <div>
    <Modal v-model="showIframe" fullscreen title="电子签约认证" @on-ok="submitIframe" @on-cancel="cancelIframe">
      <div class="iframe-border">
        <iframe :src="ifUrl" frameborder="0"></iframe>
      </div>
    </Modal>
  </div>
</template>

<script>
export default {
  name: "index",
  data(){
    return{
      ifUrl:'',
      showIframe:false
    }
  },
  methods:{
    show(e){
      this.showIframe=true
      this.ifUrl = e
    },
    cancelIframe(){

    },
    submitIframe(){
      this.$emit('submitIframe')

    },
  }
}
</script>

<style scoped>
.iframe-border {
  width: 100%;
  height: 80vh;
  background: white;
}
.iframe-border iframe {
  width: 100%;
  height: 100%;
}

</style>
